.add-reset-page {
  width: 100%;
  max-width: 560px;
  padding: 25px;
  margin: auto;

  .heading {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 2rem;
  }

  .input-group {
    .form-floating {
      width: 100%;
    }
  }

  .reset-user-action-buttons {
    display: flex;
    justify-content: center;
    // align-items: center;
  }
}

.userBtn {
  float: right;
}

.errText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerTop {
  margin-top: 10%;
}

.f-25 {
  font-size: 14px;
}
