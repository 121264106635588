.add-verification-email-page {
  width: 100%;
  max-width: 760px;
  padding: 25px;
  margin: auto;

  .heading {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 2rem;
  }

  .input-group {
    .form-floating {
      width: 100%;
    }
  }

  .edit-user-action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }
}

.userBtn {
  float: right;
}

.errText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerTop {
  margin-top: 15%;
}

.profile-picture {
  // align-items: center;
  // display: flex;
  // justify-content: center;
  // position: relative;
  height: 100%;
}
