// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
/* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true;
/* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import '@coreui/coreui-pro/scss/coreui';
@import '@coreui/coreui-pro/scss/themes/dark';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';

// Plugins
@import 'calendar';

// If you want to add custom CSS you can put it here.
@import 'custom';

.app {
  overflow-y: scroll;
  height: 100vh;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #844c7a;
}

.cursor-pointer {
  cursor: pointer;
  // background-color: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}


.wpwl-apple-pay-button {
  -webkit-appearance: -apple-pay-button !important;
}