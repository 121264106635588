.HyperPayWidget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    height: 100vh;

    #order-payment {
        min-width: inherit;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .order-pay {
        min-width: inherit;
        min-height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button !important;
        -apple-pay-button-type: donate; /* Use any supported button type. */
    }
}